import {GetStaticProps, NextPage} from "next";
import {AllProductOutputDto, ProductControllerGetProductCategoryEnum} from "../generated-sources/openapi";
import {BackendClient} from "../utils/backendClient";
import {OrderFormTemplate} from "../components/templates/orderFormTemplate";

interface pageProps {
    products: AllProductOutputDto
}

export const getStaticProps: GetStaticProps = async (context) => {
    const products = await BackendClient.productControllerGetProduct({category: ProductControllerGetProductCategoryEnum.NewCustomersV5});

    return {
        props: {
            products: products
        }
    }
}


const Page: NextPage<pageProps> = ({products}) => {
    return (
        <OrderFormTemplate products={products} step={0}/>
    );
}

export default Page;
